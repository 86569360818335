// @flow

import * as React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'

import { BarberShop } from '../icons/barbershop'

import { breakpoints, pxtoem } from '../theme'

import {
  Address,
  Box,
  ButtonLink,
  Heading,
  Flex,
  Map,
  Layout,
  Text,
  PhoneNumber,
} from '../components'

const MapContainer = styled(Box)`
  height: 500px;
  @media screen and (min-width: ${breakpoints[2]}) {
    height: 100vh;
  }
`

const el = <div style={{ height: '100%' }} />

function LocationPage({ allAddressesJson }: any) {
  const { edges } = allAddressesJson
  const [edge] = edges
  const { line1, city, state, zipcode, phone, lat, lng } = edge.node

  const size = pxtoem(48)
  return (
    <Layout>
      <Flex justifyContent="center" alignItems="center">
        <Heading as="h1" mr={2}>
          Location
        </Heading>
        <BarberShop width={size} height={size} />
      </Flex>
      <Text as="div" textAlign="center">
        <Address mb={10}>
          {line1}
          <br />
          {city}, {state} {zipcode}
        </Address>
      </Text>

      <Flex mb={2} justifyContent="center">
        <ButtonLink bg="red" href={`tel:+1${phone}`} mr={2}>
          Call Us
        </ButtonLink>

        <ButtonLink
          bg="blue"
          href="https://www.google.com/maps/place/LNK+Yonkers+Barbers/@40.9243463,-73.8667232,15z/data=!4m5!3m4!1s0x0:0xe9d4a4f82b1e98c!8m2!3d40.9243463!4d-73.8667232"
          target="_blank"
        >
          Open in Maps
        </ButtonLink>
      </Flex>

      <MapContainer>
        <Map
          center={{ lat, lng }}
          loadingElement={el}
          containerElement={el}
          mapElement={el}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}`}
        />
      </MapContainer>
    </Layout>
  )
}

const API_KEY = 'AIzaSyBiEp9xo9XDM30YKislx695EdtHugfdRT0'

const Location = () => <StaticQuery query={query} render={LocationPage} />

export const query = graphql`
  query LocationQuery {
    allAddressesJson {
      edges {
        node {
          line1
          city
          state
          zipcode
          phone
          lat
          lng
        }
      }
    }
  }
`

export default Location
